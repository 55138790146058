import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { put, destroy } from "@rails/request.js"

// Connects to data-controller="step-sortable"
export default class extends Controller {
  connect() {
    this.createPipelineSortable();
    this.createStepsSortables();
    this.addHoverEffects();
  }

  createPipelineSortable() {
    if (!this.element.classList.contains('steps-pipeline-list')) return;

    Sortable.create(this.element, {
      group: {
        name: "steps",
        pull: false,
        put: true
      },
      filter: ".icon-wrapper",
      onStart: () => { this.dragging = true; },
      onEnd: this.rearrangeIcons.bind(this),
      onAdd: this.addStepToPipeline.bind(this)
    });
  }

  createStepsSortables() {
    const canSort = this.element.dataset.canSort === "true";
    const stepsCategories = document.querySelectorAll(".steps-category");

    stepsCategories.forEach(stepsCategory => {
      Sortable.create(stepsCategory, {
        group: {
          name: "steps",
          pull: 'clone',
          put: false
        },
        sort: false,
        filter: ".category-title"
      });
    });
  }

  addHoverEffects() {
    const wrappers = Array.from(this.element.querySelectorAll('.icon-wrapper'));
    const icons = Array.from(this.element.querySelectorAll('.icon-wrapper .fa-square-plus'));

    wrappers.forEach((wrapper, index) => {
      const mouseEnterHandler = () => {
        if (icons[index]) {
          icons[index].style.display = 'block';
          setTimeout(() => {
            icons[index].style.opacity = '1';
          }, 300);
        }
      };

      const mouseLeaveHandler = () => {
        if (icons[index]) {
          icons[index].style.opacity = '0';
          setTimeout(() => {
            icons[index].style.display = 'none';
          }, 300);
        }
      };

      // Remove previous event listeners
      wrapper.removeEventListener('mouseenter', mouseEnterHandler);
      wrapper.removeEventListener('mouseleave', mouseLeaveHandler);

      // Add new event listeners
      wrapper.addEventListener('mouseenter', mouseEnterHandler);
      wrapper.addEventListener('mouseleave', mouseLeaveHandler);
    });
  }

  rearrangeIcons(event) {
    const cards = Array.from(this.element.querySelectorAll('.card-pipeline-step'));
    const icons = Array.from(this.element.querySelectorAll('.icon-wrapper'));

    // Remove all icons
    icons.forEach(icon => icon.remove());

    // Always add an icon before the first card
    if (cards[0] && icons[0]) {
      cards[0].before(icons[0].cloneNode(true)); // clone the icon
    }

    // Add icons back in the correct places
    cards.forEach((card, index) => {
      if (index < cards.length - 1 && icons[index]) {
        // Not the last card, add icon after it
        card.after(icons[index]);
      }
    });

    // If there's an icon left, append it to the end
    if (icons.length > cards.length) {
      this.element.append(icons[icons.length - 1]);
    } else if (this.element.lastChild && this.element.lastChild.nodeType === Node.ELEMENT_NODE && !this.element.lastChild.classList.contains('icon-wrapper')) {
      // If the last child is not an icon, append a new icon
      this.element.append(icons[0].cloneNode(true));
    }

    // Destroy the old Sortable instances and create new ones
    this.createStepsSortables.bind(this);
    this.createStepsSortables.bind(this);
    this.addHoverEffects.bind(this);

    const organizationType = this.element.dataset.organizationType;
    const stepId = event.item.dataset.stepId;
    // Get all step elements within the pipeline
    const stepElements = Array.from(this.element.querySelectorAll('.card-pipeline-step'));
    // Find the position of the added step
    const stepPosition = stepElements.findIndex(element => element.dataset.stepId === stepId) + 1;

    put(`/ats/${organizationType}/jobs/${event.item.dataset.jobId}/pipeline/steps/sort`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({
        id: stepId,
        position: stepPosition
      })
    });
  }

  async removeStep(event) {
    const organizationType = this.element.dataset.organizationType;
    const stepId = event.target.closest('.card-pipeline-step').dataset.stepId;
    const jobId = event.target.closest('.card-pipeline-step').dataset.jobId;

    await destroy(`/ats/${organizationType}/jobs/${jobId}/pipeline/steps/${stepId}`, {
      responseKind: "turbo-stream"
    });
  }

  async addStepToPipeline(event) {
    const stepId = event.item.dataset.stepId;
    const pipelineId = this.element.dataset.pipelineId;
    const jobId = event.item.dataset.jobId;

    const organizationType = this.element.dataset.organizationType;
    // Get all step elements within the pipeline
    const stepElements = Array.from(this.element.querySelectorAll('.card-pipeline-step'));

    // Find the position of the added step
    const stepPosition = stepElements.findIndex(element => element.dataset.stepId === stepId) + 1;

    await put(`/ats/${organizationType}/jobs/${jobId}/pipeline/add_step`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({
        step_id: stepId,
        pipeline_id: pipelineId,
        step_position: stepPosition
      })
    });
  }
}
