import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"
import Trix from "trix"

// Connects to data-controller="combined-mentions"
export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
  }

  initializeTribute() {
    this.tribute = new Tribute({
      collection: [
         {
           trigger: "{{",
           allowSpaces: true,
           lookup: "key",
           menuShowMinLength: 0,
           menuItemLength: 10,
           menuContainer: document.querySelector('.modal-body .os-content'),
           values: [
            { key: 'Candidate Name', value: 'candidate_name'},
            { key: 'Job Title', value: 'job_title'},
            { key: 'Company Name', value: 'company_name'},
            { key: 'Job Headers', value: 'job_headers'},
            { key: 'Candidate Step', value: 'candidate_step'},
            { key: 'Company Employee Name', value: 'company_employee_name'},
            { key: 'Hunter Employee Name', value: 'hunter_employee_name'},
            { key: 'Hiring Manager Name', value: 'hiring_manager_name'},
            { key: 'Job Owner Name',  value: 'job_owner_name'}
           ]
         }
       ]
    });

    this.tribute.attach(this.fieldTarget)
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
  }

  replaced(event) {
    let mention = event.detail.item.original

    this.editor.insertHTML(`<del>${mention.key}</del>&nbsp;`)
    this.editor.insertHTML(' ')
  }

  _pasteHtml(html, startPos, endPos) {
    let range = this.editor.getSelectedRange()
    let position = range[0]
    let length = endPos - startPos

    this.editor.setSelectedRange([position - length, position])
    this.editor.deleteInDirection("backward")
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }
}
