import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="remote-modal"
export default class extends Controller {
  connect() {
    this.element.addEventListener('hide.bs.modal', (e) => {
      if (this.hasUnsavedData() && !confirm("You have unsaved data. Are you sure you want to close this modal?")) {
        e.preventDefault()
      }
    });

    this.modal = new bootstrap.Modal(this.element)
    this.open()
  }

  open() {
    if (!this.isOpen()) {
      this.modal.show()
    }
  }

  close(e) {
    if (this.isOpen()) {
      this.modal.hide()
    }
  }

  closeAfterSubmit(e) {
    if (e.detail.success && !this.keepModalOpen(e)) {
      this.removeUnsavedData()

      this.close(e)
    }
  }

  isOpen() {
    return this.element.classList.contains("show")
  }

  keepModalOpen(e) {
    return e.detail.fetchResponse.response.headers.get('keep_modal_open') == 'true'
  }

  hasUnsavedData() {
    return this.element.querySelectorAll('form[data-changed="true"]').length > 0
  }

  removeUnsavedData() {
    this.element.querySelectorAll('form[data-changed="true"]').forEach((form) => {
      form.removeAttribute('data-changed')
    })
  }
}
