import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="copy-button"
export default class extends Controller {
  static targets = ["feedback"]

  copy(event) {
    event.preventDefault();
    const link = event.currentTarget.dataset.link;

    navigator.clipboard.writeText(link).then(() => {
      this.showFeedback();
    }).catch((error) => {
      console.error("Error copying text: ", error);
    });
  }

  showFeedback() {
    this.feedbackTarget.classList.remove("d-none");
    setTimeout(() => {
      this.feedbackTarget.classList.add("d-none");
    }, 2000);
  }
}
