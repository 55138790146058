import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="candidate-job--add-to-job"
export default class extends Controller {
  static targets = ["candidate"]

  connect() {
    this.elems = [];
    document.addEventListener('turbo:frame-load', this.handleFrameLoad.bind(this));
  }

  disconnect() {
    document.removeEventListener('turbo:frame-load', this.handleFrameLoad.bind(this));
  }

  handleFrameLoad(event) {
    const frame = event.target;
    if (frame.contains(this.element.querySelector('#candidates-card-list'))) {
      const buttons = frame.querySelectorAll('a.btn-add-candidate')
      buttons.forEach(button => {
        const candidateId = button.dataset.candidateId
        const existingCandidate = this.elems.find(elem => elem.id === candidateId)

        if (existingCandidate) {
          button.classList.add('btn-danger')
          button.classList.remove('btn-outline-primary')
          button.innerHTML = '<i class="fas fa-user-xmark"></i>'
        } else {
          button.classList.add('btn-outline-primary')
          button.classList.remove('btn-danger')
          button.innerHTML = '<i class="fas fa-user-plus"></i>'
        }
      })

      this._updateHiddenInput()
    }
  }

  submitForm(event) {
    const form = `form#${event.target.dataset.formId}`
    Rails.fire(this.element.querySelector(form), 'submit')
  }

  toggleItem(event) {
    event.preventDefault()

    const candidateId = event.currentTarget.dataset.candidateId
    const candidateName = event.currentTarget.dataset.candidateName
    const button = document.querySelector(`a[data-candidate-id="${candidateId}"]`)
    const addedCandidatesList = document.getElementById('added-candidates-list')

    this._addCandidateToList(candidateId, candidateName)
    this._toogleButton(button)
    this._updateCandidatesListCard()
    this._updateCandidatesList(addedCandidatesList)
    this._updateHiddenInput()


    console.log(this.elems)
  }

  _addCandidateToList(candidateId, candidateName) {
    const existingCandidate = this.elems.find(elem => elem.id === candidateId)

    if (existingCandidate) {
      this.elems = this.elems.filter(elem => elem.id !== candidateId)
    } else {
      this.elems.push({ id: candidateId, name: candidateName })
    }
  }

  _toogleButton(button) {
    button.classList.toggle('btn-outline-primary')
    button.classList.toggle('btn-danger')

    if (button.classList.contains('btn-danger')) {
      button.innerHTML = '<i class="fas fa-user-xmark"></i>'
    } else {
      button.innerHTML = '<i class="fas fa-user-plus"></i>'
    }
  }

  _updateCandidatesListCard() {
    const accordionCards = document.getElementById('accordionCards')

    if (this.elems.length > 0) {
      accordionCards.classList.remove('d-none')
    } else {
      accordionCards.classList.add('d-none')
    }

    const addedCandidateCount = document.getElementById('added-candidates-count')
    addedCandidateCount.innerHTML = this.elems.length
  }

  _updateCandidatesList(addedCandidatesList) {
    addedCandidatesList.innerHTML = ''

    this.elems.forEach(elem => {
      var novoHtml = `
        <li class="d-flex align-items-center justify-content-between" data-candidate-id="${elem.id}">
          <div>
            <i class="fas fa-arrow-right me-2 small"></i>
            <span>${elem.name}</span>
          </div>
          <div>
            <a href="#!" class="btn btn-link text-danger p-0" data-candidate-id="${elem.id}" data-action="click->candidate-job--add-to-job#toggleItem">
              <i class="fas fa-trash-alt"></i>
            </a>
          </div>
        </li>
        <hr>
      `;

      addedCandidatesList.innerHTML += novoHtml
    })
  }

  _updateHiddenInput() {
    const hiddenInputs = document.getElementById('hiddenInputs')
    hiddenInputs.innerHTML = ''

    this.elems.forEach(elem => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'ats_candidate_job[candidate_ids][]'
      input.value = elem.id
      hiddenInputs.appendChild(input)
    })
  }
}
