import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="job-publication"
export default class extends Controller {
  static targets = ["inputPublishJob", "inputPublishHome"]

  connect() {
  }

  togglePublishJob(event) {
    if (!this.inputPublishJobTarget.checked) {
      this.inputPublishHomeTarget.checked = false
    }

    Rails.fire(this.element, 'submit');
  }

  togglePublishHome(event) {
    if (this.inputPublishHomeTarget.checked) {
      this.inputPublishJobTarget.checked = true
    }

    Rails.fire(this.element, 'submit');
  }
}
