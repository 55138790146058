import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

// Connects to data-controller="color"
export default class extends Controller {
  connect() {
  }

  change(e) {
    e.preventDefault();

    if (e.currentTarget.dataset.parent == 'darkness') {
      this.changeDarkness(e.currentTarget.dataset.darkness);
    } else if (e.currentTarget.dataset.parent == 'color') {
      this.changeColor(e.currentTarget.dataset.color);
    }
  }

  changeDarkness(target) {
    patch(`${this.element.dataset.url}`, {
      responseKind: 'json',
      body: JSON.stringify({
        ats_landing_page: {
          'darkness': target
        }
      })
    });
    if (target == 'dark') {
      document.getElementById('btnToDark').style.display = 'none';
      document.getElementById('btnToLight').style.display = 'block';
    } else {
      document.getElementById('btnToDark').style.display = 'block';
      document.getElementById('btnToLight').style.display = 'none';
    }
  }

  changeColor(target) {
    patch(`${this.element.dataset.url}`, {
      responseKind: 'json',
      body: JSON.stringify({
        ats_landing_page: {
          'color_scheme': target
        }
      })
    });
  }
}
