import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'

// // Connects to data-controller="key-question-edit"
export default class extends Controller {
  connect() {
    useClickOutside(this)
  }

  in() {
    this.element.classList.add('card-key-question-edit--active')
  }

  out() {
    this.element.classList.remove('card-key-question-edit--active')
  }

  changeType(event) {
    const questionType = event.target.value;

    const keyQuestionCard = event.target.closest(".key-question-card");
    const typeElements = keyQuestionCard.querySelectorAll(
      ".type-validation, .type-multiple, .type-selection, .type-skill_level, .type-open_question"
    );

    typeElements.forEach((element) => {
      element.classList.add("d-none");
      element.querySelectorAll("input").forEach((input) => {
        input.disabled = true;
      });
    });

    const selectedTypeElements = keyQuestionCard.querySelectorAll(
      `.type-${questionType}`
    );

    selectedTypeElements.forEach((element) => {
      element.classList.remove("d-none");
      element.querySelectorAll("input").forEach((input) => {
        input.disabled = false;
      });
    });

    const questionTypeSelector = keyQuestionCard.querySelector(
      ".question-type-selector i"
    );

    questionTypeSelector.className = "";
    questionTypeSelector.classList.add(questionType);
  }
}
