import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

// Connects to data-controller="landing-page-section-activation"
export default class extends Controller {
  static targets = ["input"]

  toggle(e) {
    if (this.inputTarget.checked) {
      this.activate(this.inputTarget.dataset.url)
    } else {
      this.deactivate(this.inputTarget.dataset.url)
    }
  }

  activate(url) {
    patch(`${url}/activate`, {
      responseKind: "turbo-stream"
    })
  }

  deactivate(url) {
    patch(`${url}/deactivate`, {
      responseKind: "turbo-stream"
    })
  }
}
