import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="candidate-job-filter"
export default class extends Controller {
  static targets = [ "button" ]

  connect() {
  }

  filter(event) {
    this.toogleButton(event)
    this.filterCandiate(event)
  }

  toogleButton(event) {
    const klass = event.currentTarget.dataset.class
    event.currentTarget.classList.toggle(`bg-outline-${klass}`)
    event.currentTarget.classList.toggle(`bg-${klass}`)
  }

  filterCandiate(event) {
    const klass = event.currentTarget.dataset.status
    const candidates = document.querySelectorAll(`[data-candidate-job-status="${klass}"]`)
    candidates.forEach(candidate => {
      candidate.parentNode.classList.toggle('d-none')
    })
  }
}
