import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="candidate-job--send-whatsapp"
export default class extends Controller {
  static targets = [ "message", "phone" ]

  send_message(event) {
    event.preventDefault();
    var text = this.messageTarget.value.replace(/\n/g, '%0A');

    window.open(`https://wa.me/${this.phoneTarget.value}?text=${text}`, '_blank');

    Rails.fire(this.element, 'submit');
  }
}
