import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="initialize-common-script"
export default class extends Controller {
  connect() {
    if (typeof Common !== 'undefined') {
      let common = new Common();
    }
  }
}
