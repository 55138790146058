import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    message: String,
    type: String,
    title: String
  }

  connect() {
    this.show();
  }

  show() {
    const { messageValue, typeValue, titleValue } = this;

    switch (typeValue) {
      case "success":
        toastr.success(messageValue, titleValue);
        break;
      case "error":
        toastr.error(messageValue, titleValue);
        break;
      case "warning":
        toastr.warning(messageValue, titleValue);
        break;
      case "info":
        toastr.info(messageValue, titleValue);
        break;
      default:
        toastr.info(messageValue, titleValue);
    }

    this.element.remove();
  }
}
