import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="unsaved-data"
export default class extends Controller {
  connect() {
    this.element.addEventListener('change', (e) => {
      this.change()
    });
  }

  change() {
    this.element.setAttribute("data-changed", true)
  }
}
