import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { put } from "@rails/request.js"

// Connects to data-controller="candidate-job-sortable"
export default class extends Controller {
  connect() {
    this.modalWrap = null

    Sortable.create(this.element, {
      scroll: true,
      onEnd: this.onEnd.bind(this),
      animation: 150,
      filter: '.disabled',
      group: "candidate-job",
      ghostClass: "opacity-50",
      scrollSensitivity: 100, // px, how near the mouse must be to an edge to start scrolling.
      scrollSpeed: 10, // px, speed of the scrolling
      bubbleScroll: true
    })
  }

  onEnd(event) {
    if ((event.to === event.from) && (event.newIndex === event.oldIndex)) {
      return
    }

    var jobId = event.item.dataset.jobId
    var candidateJobId = event.item.dataset.candidateJobId
    var resource = event.item.dataset.resource || "jobs"
    var organization = this.element.dataset.organization || "hunter"
    var stepId = event.to.dataset.stepId

    put(`/ats/${organization}/${resource}/${jobId}/candidate_jobs/${candidateJobId}/update_step`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({
        step_id: stepId,
        position: event.newIndex
      })
    })
  }
}
