import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="job-description"
export default class extends Controller {
  connect() {
  }

  static targets = ['content', 'loading'];

  displayLoading(event) {
    // console.log("displayLoading")
    this.loadingTarget.classList.remove('d-none');
    // this.contentTarget.classList.add('d-none');
  }
}
