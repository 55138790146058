import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

// Connects to data-controller="key-question-sortable"
export default class extends Controller {
  connect() {
    Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    var index = event.newIndex + 1
    event.item.querySelector(".position").value = index
  }
}
