import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="key-question-remove-all"
export default class extends Controller {
  static targets = ["questionWrapper"];

  removeAll(event) {
    event.preventDefault();

    this.questionWrapperTargets.forEach((wrapper) => {
      const removeButton = wrapper.querySelector(".remove-question.remove_fields");
      this.removeBox(removeButton);
    });
  }

  removeBox(button) {
    const wrapperClass = button.dataset.wrapperClass || "nested-fields";
    const nodeToDelete = button.closest(`.${wrapperClass}`);
    const triggerNode = nodeToDelete.parentElement;

    const beforeRemove = new Event("cocoon:before-remove");
    triggerNode.dispatchEvent(beforeRemove);

    if (!beforeRemove.defaultPrevented) {
      const timeout = triggerNode.dataset.removeTimeout || 0;

      setTimeout(() => {
        if (button.classList.contains("dynamic")) {
          nodeToDelete.remove();
        } else {
          button.previousElementSibling.value = "1";
          nodeToDelete.style.display = "none";
        }
        const afterRemove = new Event("cocoon:after-remove");
        triggerNode.dispatchEvent(afterRemove);
      }, timeout);
    }
  }
}
