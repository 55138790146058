import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="refine-with-ai"
export default class extends Controller {
  static targets = [ 'form', 'buttons' ]

  connect() {
  }

  showButton(e) {
    e.preventDefault();

    this.formTarget.classList.toggle('d-none');
    this.formTarget.classList.toggle('show');
    this.buttonsTarget.remove();
  }
}
