import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="email-variable-selector"
export default class extends Controller {
  connect() {
    this.updateTemplateIcon(this.element);
    this.element.closest('.automation-fields').querySelector('.email-template-subject').addEventListener('input', (event) => { this.updateTemplateIcon(event.target) });
    this.element.closest('.automation-fields').querySelector('.email-template-body').addEventListener('input', (event) => { this.updateTemplateIcon(event.target) });
  }

  updateTemplateIcon(element) {
    var subjectContent = element.closest('.automation-fields').querySelector('.email-template-subject').value;
    var bodyContent = element.closest('.automation-fields').querySelector('.email-template-body').value;

    // return subjectContent.length > 4 && bodyContent.length > 10;
    var has_template = subjectContent.length > 4 && bodyContent.length > 10;
    var iconElement = element.closest('.automation-fields').querySelector('.toggleEmailEditorButton i');


    console.log(has_template);

    if (has_template) {
      iconElement.classList.remove('fa-square-plus');
      iconElement.classList.add('fa-square-check');
    } else {
      iconElement.classList.remove('fa-square-check');
      iconElement.classList.add('fa-square-plus');
    }
  }
}
